import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdownServices, setDropdownServices] = useState(false);
  const [dropdownPricing, setDropdownPricing] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const onMouseEnterServices = () => {
    if (window.innerWidth < 960) {
      setDropdownServices(false);
    } else {
      setDropdownServices(true);
    }
  };

  const onMouseLeaveServices = () => {
    setDropdownServices(false);
  };

  const onMouseEnterPricing = () => {
    if (window.innerWidth < 960) {
      setDropdownPricing(false);
    } else {
      setDropdownPricing(true);
    }
  };

  const onMouseLeavePricing = () => {
    setDropdownPricing(false);
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            DxT
            <i className='fab fa-typo3' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li
              className='nav-item'
              onMouseEnter={onMouseEnterServices}
              onMouseLeave={onMouseLeaveServices}
            >
              <Link
                // to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services <i className="fas fa-caret-down" />
              </Link>
              {dropdownServices && (
                <div className='dropdown-menu'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Link to='/services/data-analytics' className='dropdown-link' onClick={closeMobileMenu}>
                            Data Analytics
                          </Link>
                        </td>
                        <td>
                          <Link to='/services/property-management' className='dropdown-link' onClick={closeMobileMenu}>
                            Property Management
                          </Link>
                        </td>
                        <td>
                          <Link to='/services/project-management' className='dropdown-link' onClick={closeMobileMenu}>
                            Project Management
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link to='/services/consulting' className='dropdown-link' onClick={closeMobileMenu}>
                            Consulting Services
                          </Link>
                        </td>
                        <td>
                          <Link to='/services/website' className='dropdown-link' onClick={closeMobileMenu}>
                            Website Development
                          </Link>
                        </td>
                        <td>
                          <Link to='/services/mobile-apps' className='dropdown-link' onClick={closeMobileMenu}>
                            Mobile App Development
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </li>
            {/* <li
              className='nav-item'
              onMouseEnter={onMouseEnterPricing}
              onMouseLeave={onMouseLeavePricing}
            >
              <Link
                to='/pricing'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Pricing
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                to='/about-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
          </ul>
          {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
