import React, { useState, useRef } from 'react';
import './Cards.css';
import CardItem from './CardItem';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

function Cards({ servicesRef }) {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [serviceRequest, setServiceRequest] = useState('');
  const [notes, setNotes] = useState('');
  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    window.scrollTo(0, 0);
    if (path) {
      navigate(path);
    } else {
      if (formRef.current) {
        const topOffset = formRef.current.offsetTop - 100; // Adjust offset as needed
        window.scrollTo({
          top: topOffset,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceID = 'service_v8n3mhm';
    const templateID = 'template_gzgkg1s';
    const userID = '9zZu49SvWf8jbg-cZ';

    const templateParams = {
      name,
      companyName,
      email,
      serviceRequest,
      notes,
    };

    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
    } catch (error) {
      // console.error('Email sending failed:', error);
      // Handle error state or alert the user
    }

    const form = e.target;
    form.reset(); // Reset form fields
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'serviceRequest':
        setServiceRequest(value);
        break;
      case 'additionalNotes':
        setNotes(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className='cards' ref={servicesRef}>
      <h1 style={{ position: 'absolute', marginBottom: 10, color: 'black', textAlign: 'flex-start' }}>Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-dataAnalytics1.png'
              text="From dashboards to analytic scripts, extend your ability to collect data and metrics."
              label='Data Analytics'
              onClick={() => handleCardClick('/services/data-analytics')}
              path='/services/data-analytics'
            />
            <CardItem
              src='images/img-propertyMgmt2.png'
              text='Build out your property management software with custom solutions for your portfolio.'
              label='Property Management'
              onClick={() => handleCardClick('/services/property-management')}
              path='/services/property-management'
            />
            <CardItem
              src='images/img-projectMgmt1.png'
              text='Request and build custom project management softwares to fit your needs.'
              label='Project Management'
              onClick={() => handleCardClick('/services/project-management')}
              path='/services/project-management'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-consulting2.png'
              text="Find tailor-made solutions, from custom software development to seamless implementation."
              label='Software Consulting Services'
              onClick={() => handleCardClick('/services/consulting')}
              path='/services/consulting'
            />
            <CardItem
              src='images/img-websiteDev.png'
              text="Create, update, or expand your own website with features tailored to your needs."
              label='Website Development'
              onClick={() => handleCardClick('/services/website')}
              path='/services/website'
            />
            <CardItem
              src='images/img-mobileApp1.png'
              text="Take your app ideas from concept to reality with our help."
              label='Mobile App Development'
              onClick={() => handleCardClick('/services/mobile-apps')}
              path='/services/mobile-apps'
            />
          </ul>
        </div>
      </div>
      <div className='email-section'>
        <div className='email-form' ref={formRef}>
          <h2>Submit An Inquiry</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name*</label>
              <input type='text' name='name' value={name} onChange={handleChange} required />
            </div>
            <div>
              <label>Company Name*</label>
              <input type='text' name='companyName' value={companyName} onChange={handleChange} required />
            </div>
            <div>
              <label>Email*</label>
              <input type='email' name='email' value={email} onChange={handleChange} required />
            </div>
            <div>
              <label>Add your inquiry or request here*</label>
              <textarea name='serviceRequest' value={serviceRequest} onChange={handleChange} required></textarea>
            </div>
            <div>
              <label>Additional Notes (optional)</label>
              <textarea name='additionalNotes' value={notes} onChange={handleChange}></textarea>
            </div>
            <button type='submit'>Submit</button>
          </form>
          {submitted && <p style={{ color: 'green', marginTop: '10px' }}>Inquiry submitted successfully!</p>}
        </div>
        <div className='email-description'>
          <h3>What Can We Help You With?</h3>
          <p style={{ marginBottom: 20 }}>Whether you're looking for development, implementation, or software consulting services, DxT is here to help. </p>
          <p style={{ marginBottom: 20 }}>If you would like to start the development process, or get more information, please submit an inquiry.</p>
          <p>NOTE: We do our best to reply to all inquiries within 2 business days. We are happy to schedule calls if additional information is needed.</p>
        </div>
      </div>
    </div>
  );
}

export default Cards;
