import React from 'react';
import './Pricing.css';
import { useNavigate } from 'react-router-dom';

const pricingData = [
  {
    title: 'Data Analytics',
    description: 'Unlock the potential of your data with our comprehensive data analytics services.',
    path: '/data-analytics'
  },
  {
    title: 'Property Management',
    description: '',
    path: '/website-development'
  },
  {
    title: 'Project Management',
    description: '',
    path: '/mobile-app-development'
  },
  {
    title: 'Consulting Services',
    description: '',
    path: '/project-management'
  },
  {
    title: 'Website Development',
    description: '',
    path: '/document-management'
  },
  {
    title: 'Mobile App Development',
    description: '',
    path: '/consulting-services'
  }
];

const Pricing = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="pricing-container">
      <h1>Pricing</h1>
      <h3 style={{marginBottom: 20, fontWeight: 500, fontSize: 20}}>Pricing is determined on the scope of the service provided, the number of hours required, and the category in which the service falls under. Below is our table of what might fall where.</h3>
      <table className="pricing-table">
        <thead>
          <tr>
            <th>Service</th>
            <th>Description</th>
            {/* <th>Details</th> */}
          </tr>
        </thead>
        <tbody>
          {pricingData.map((item, index) => (
            <tr key={index}>
              <td>{item.title}</td>
              <td>{item.description}</td>
              {/* <td>
                <button onClick={() => navigateTo(item.path)}>Details</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Pricing;
