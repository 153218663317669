import React from 'react';
import '../../App.css';
import Footer from '../Footer';

function AboutUs() {

  const handleEmailButtonClick = () => {
    window.location.href = 'mailto:dxt.applications@email.com?subject=New%20Client%20Request';
  };

  return (
    <>
       <div className='about-us'>
      <div className='header'>
        <h1>About Us</h1>
      </div>
      <div className='body'>
        <div className='content'>
          <p>At DxT, our mission is to provide emerging and existing businesses with quality software that is meticulously tailored to meet their specific needs and objectives. We are passionate about empowering businesses with innovative software solutions. We understand that every business is unique, and that's why we don't believe in off-the-shelf software.</p>
          <p>With over 20 years of experience and expertise in the software industry, we specialize in building customized mobile and web applications, as well as analytic software tailored to your business. Whether you're looking to develop a new app, streamline your operations, enhance customer engagement, or gain valuable insights from your data, we have the skills and resources to bring your vision to life.</p>
          <p>We are committed to delivering high-quality software solutions that empower businesses to make data-driven decisions and achieve their goals.</p>
          <p>What sets us apart is our unwavering commitment to excellence. We take the time to truly understand your business goals and requirements, ensuring that the solutions we deliver not only meet but exceed your expectations. Our dedicated team of professionals works tirelessly to deliver high-quality software solutions that empower businesses to make informed, data-driven decisions and achieve their long-term objectives.</p>
          <p>At DxT, we believe that technology should be a catalyst for growth and innovation. Let us be your trusted partner on your journey to success.</p>
          <p></p>
          <p>For any inquiries, please reach out via the Contact Us button below.</p>
        </div>
      </div>
      <div className='cta'>
          <button onClick={handleEmailButtonClick}>Contact Us</button>
      </div>
    </div>
      <Footer />
    </>
  );
}

export default AboutUs;